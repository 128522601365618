export default {
  name: 'global',
  store() {
    return {
      isMobileMenuVisible: false,
      isMinicartVisible: false,
      isPredictiveSearchVisible: false,
      isWindowScrolled: false,
      isCustomiserVisible: false,
      isUSPVisible: -1,
      isFiltering: false,
      theme: null,
      cart: null,
      init() {
        window.addEventListener('scroll', this.onWindowScrollHandler.bind(this))
        this.setTheme()
        this.initLiquidAJaxCart()
        this.toggleIsFiltering.bind(this)

        // 💡 prevent user to accidentaly navigate away from open customiser
        // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
        if (process.env.NODE_ENV !== 'development') {
          window.addEventListener('beforeunload', (event) => {
            if (this.isCustomiserVisible) {
              event.preventDefault();
              // Chrome requires returnValue to be set.
              const msg = 'Are you sure you want to navigate away? Otherwise, use the form controls below.';
              // in most browsers custom message is not possible
              event.returnValue = msg;
              return msg
            }
          });
        }
      },
      openCustomiser() {
        this.isCustomiserVisible = true
      },
      closeCustomiser() {
        this.isCustomiserVisible = false
      },
      get bodyClasses() {
        let classes = []

        if (this.isMobileMenuVisible) {
          classes.push('mobile-menu-visible')
        }

        if (this.isCustomiserVisible) {
          classes.push('customiser-visible')
        }

        if (this.isMinicartVisible) {
          classes.push('minicart-visible')
        }

        return classes || ''
      },
      // todo: improve this 🗑️
      setTheme() {
        const bodyClasses = document.querySelector('body').classList;
        if (bodyClasses.contains('BXC')) {
          this.theme = 'BXC';
        } else if (bodyClasses.contains('OSP')) {
          this.theme = 'OSP';
        }
      },
      openMobileMenu() {
        this.isMobileMenuVisible = true
      },
      closeMobileMenu() {
        this.isMobileMenuVisible = false
      },
      toggleMobileMenu() {
        this.isMobileMenuVisible = !this.isMobileMenuVisible
      },
      toggleIsFiltering() {
        this.isFiltering = !this.isFiltering
      },
      onWindowScrollHandler() {
        const isScrolled = window.scrollY > 0
        this.isWindowScrolled = isScrolled
        document.body.classList[isScrolled ? 'add' : 'remove']('scrolled')
      },
      openUSPDrawer(idx) {
        if (typeof idx !== 'number') {
          console.error('no idx found')
        }
        this.isUSPVisible = idx
      },
      configAjaxCart() {
        window.liquidAjaxCart.conf('updateOnWindowFocus', false)
        window.liquidAjaxCart.conf('mutations', [removeCrossProductsWithoutMain])
      },
      initLiquidAJaxCart() {
        try {
          if (window.liquidAjaxCart?.init) {
            this.configAjaxCart()
          } else {
            document.addEventListener('liquid-ajax-cart:init', this.configAjaxCart)
          }
          document.addEventListener('liquid-ajax-cart:request-start', this.handleRequestStart.bind(this))
          document.addEventListener('liquid-ajax-cart:request-end', this.handleRequestEnd.bind(this))
        } catch (error) {
          console.error('Error initializing Liquid Ajax Cart:', error.message)
        }
      },
      handleRequestStart(event) {
        const { requestState } = event.detail
        if (requestState.requestType === 'add') {
          const innerFormId = requestState.info.initiator?.firstElementChild?.getAttribute('id')

          // 💡 test if it's product add to cart
          if (innerFormId && innerFormId == 'product_form_id') {
            const crossProductsStore = window.Alpine.store('crossProducts')
            //const mainVariantId = crossProductsStore.mainVariantId.id;
            const mainVariantId = window.prodify.getMainVariantIdFromMetaHelper();
            const crossProductEntries = Object.entries(crossProductsStore.current)

            if (
              (requestState.requestBody instanceof FormData || requestState.requestBody instanceof URLSearchParams) &&
              crossProductEntries.length
            ) {
              const mainId = requestState.requestBody.get('id')

              let clonedItems = []
              let hiddenItems = []
              let items = crossProductEntries.map(([crossProductId, cp], idx) => {
                
                const main_product_id = parseInt(mainId)

                if (!main_product_id) {
                  console.error('missing main product id')
                }

                if (cp.cloneVariantId) {
                  clonedItems.push({
                    id: cp.cloneVariantId,
                    quantity: cp.optedOut ? 0 : (cp.skip ? 0 : 1),
                    properties: {
                      main_product_id
                    }
                  })
                }
                if(cp.hasHiddenCp && !cp.optedOut && !cp.skip) {
                  const selectedSize = window.main_variants[mainVariantId];
                  const hiddenCp = window.hidden_cps[crossProductId];
                  let foundHiddenCpVariant = null;
                  for(let i = 0; i < hiddenCp.variants.length; ++i) {
                    const hiddenCpVariant = hiddenCp.variants[i];
  
                    if(hiddenCpVariant.option1 == selectedSize) {
                      foundHiddenCpVariant = hiddenCpVariant;
                      break;
                    }
                  }
                  if(foundHiddenCpVariant) {  
                    hiddenItems.push({
                      id: foundHiddenCpVariant.id,
                      quantity: 1,
                      properties: {
                        main_product_id
                      }
                    });
                    /*
                    return {
                      id: foundHiddenCpVariant.id,
                      quantity: 1,
                      properties: {
                        main_product_id
                      }
                    } 
                    */   
                  } 
                } 
                return {
                  id: cp.variant.id,
                  quantity: cp.optedOut ? 0 : (cp.skip ? 0 : 1),
                  properties: {
                    main_product_id
                  }
                }
              })

              items = items.filter(item => item.quantity > 0);
              clonedItems = clonedItems.filter(item => item.quantity > 0);              
              hiddenItems = hiddenItems.filter(item => item.quantity > 0);              

              const buildFormData = (formData, key, data) => {
                if (data === Object(data) || Array.isArray(data)) {
                  for (const i in data) buildFormData(formData, `${key}[${i}]`, data[i])
                } else data && formData.append(key, data)
              }

              buildFormData(requestState.requestBody, 'items', [...items, ...clonedItems, ...hiddenItems])
            }
          }

          if (innerFormId && innerFormId == 'sample-form') {
            event.preventDefault()
          }
        }
      },
      handleRequestEnd(event) {

        const { requestState, cart, previousCart, sections } = event.detail
        const innerFormId = requestState.info.initiator?.firstElementChild?.getAttribute('id')
        // 💡 test if it's product add to cart
        if (requestState.requestType === 'add' && requestState.responseData?.ok && innerFormId && innerFormId == 'product_form_id') {
          this.cart = cart
          if (this.isCustomiserVisible) {
            this.isCustomiserVisible = false
          }
          const customiserEl = document.querySelector('#product-customiser');
          if (customiserEl) {
            customiserEl.dispatchEvent(new CustomEvent('reset'));
          } else {
            console.error('Customiser element not found.');
          }
        }

        if (requestState.requestType === 'add' && requestState.responseData?.ok && innerFormId && innerFormId == 'sample-form') {
          this.cart = cart
          if (!this.isMinicartVisible) {
            this.isMinicartVisible = true
          }
        }
      },
    }
  }
}

function removeCrossProductsWithoutMain() {
  try {
    const cart = window?.liquidAjaxCart?.cart

    if (!cart) {
      throw new Error('Ajax cart mutation error: Cart not found.')
    }

    if (cart.items_count == 0 || !cart.items?.length) {
      return null
    }

    const mainProductIds = cart.items.filter((item) => item.properties?.is_main_product)?.map((item) => item.id)

    let updates = {}
    cart.items.forEach((item) => {
      const mainProductIdAttribute = item?.properties?.main_product_id
        ? parseInt(item?.properties?.main_product_id)
        : undefined
      const isCrossProduct = typeof mainProductIdAttribute === 'number'

      if (!isCrossProduct || item.properties?.is_sample) {
        return
      }

      const hasMainInCart = mainProductIds.includes(mainProductIdAttribute)

      if (hasMainInCart) {
        return
      } else {
        Object.assign(updates, { [JSON.stringify(item.id)]: 0 })
      }
    })

    if (Object.keys(updates).length > 0) {
      return {
        requests: [
          {
            type: 'update',
            body: { updates }
          }
        ]
      }
    }
    return null
  } catch (error) {
    console.error('Error removing cross products without main:', error.message)
    return null
  }
}
